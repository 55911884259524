import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImportModel } from 'app/models/import.model';
import { ClientService } from 'app/services/client.service';
import { Papa, ParseResult } from 'ngx-papaparse';
import { combineLatest, forkJoin, fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import * as shp from 'shpjs';
import {
  FILE_EXTENSION,
  MappingLayer,
  ParseResultData,
  TextFileRow,
} from './soilsampling.types';
import { DataFileService } from 'app/services/data-file.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-soilsampling',
  templateUrl: './soilsampling.component.html',
  styleUrls: ['./soilsampling.component.scss'],
})
export class SoilsamplingComponent implements OnInit {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();

  txtProps: string[] = [];
  shpProps: string[] = [];

  mappingLayers: MappingLayer[] = [];

  selShpProp: string;
  selTxtProp: string;
  selectedMappingLayer: MappingLayer;
  deleteCoordinateLayerAfterImport = false;

  rows: TextFileRow[] = null;
  csvFileMissing = false;
  shpFileMissing = false;
  dbfFileMissing = false;
  needAdditionalFiles = false;
  soilsamplingFiles = {
    shp: null,
    txt: null,
    dbf: null,
    shx: null,
    fc: null,
  };
  gotFiles: boolean;
  noShpRows: boolean;
  precheckMatch: boolean;
  matchCount: number;

  constructor(
    private translateService: DvToolbarTranslateService,
    private snackBar: MatSnackBar,
    private papa: Papa,
    private clientSrv: ClientService,
    private fileService: DataFileService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.fileService.getGeodata(),
      this.clientSrv.getSoilSamplingCoordinateLayers(),
    ]).subscribe(([geodata, layers]) => {
      this.mappingLayers.push(
        ...geodata
          .filter((geodata) => {
            return geodata.fileType === 'Kartering';
          })
          .map<MappingLayer>((geoData) => {
            return {
              id: geoData.fileId,
              name: geoData.name,
              newFilestack: true,
            };
          })
      );
      this.mappingLayers.push(
        ...layers.map<MappingLayer>((layer) => {
          return { id: layer.id, name: layer.name, newFilestack: false };
        })
      );
    });
  }

  files(files: File[]): void {
    files.forEach((file) => {
      switch (file.name.toLowerCase().substring(file.name.lastIndexOf('.'))) {
        case FILE_EXTENSION.DBF:
          this.soilsamplingFiles.dbf = file;
          this.parseShp();
          break;
        case FILE_EXTENSION.SHP:
          this.soilsamplingFiles.shp = file;
          this.parseShp();
          break;
        case FILE_EXTENSION.TXT:
        case FILE_EXTENSION.CSV:
          this.soilsamplingFiles.txt = file;
          this.parseTxt();
          break;
        default:
          this.snackBar.open(
            this.translateService.t('Unknown filetype'),
            null,
            { duration: 3000 }
          );
      }
    });
  }

  parseShp(): void {
    this.updateFileStatus();

    // Don't parse shape if required files are missing
    if (this.shpFileMissing || this.dbfFileMissing) {
      return;
    }

    const shpReader = new FileReader();
    const dbfReader = new FileReader();

    forkJoin([
      fromEvent(shpReader, 'load').pipe(first()),
      fromEvent(dbfReader, 'load').pipe(first()),
    ]).subscribe(() => {
      this.soilsamplingFiles.fc = shp.combine([
        shp.parseShp(<any>shpReader.result),
        shp.parseDbf(<any>dbfReader.result, null),
      ]);
      this.updateFileStatus();
      this.listProperties();
    });

    shpReader.readAsArrayBuffer(this.soilsamplingFiles.shp);
    dbfReader.readAsArrayBuffer(this.soilsamplingFiles.dbf);
  }

  parseTxt(): void {
    // Check if file was read correctly
    if (this.soilsamplingFiles.txt === null) {
      this.snackBar.open(this.translateService.t("Can't read file"));
      return;
    }

    this.papa.parse(this.soilsamplingFiles.txt, {
      encoding: 'iso-8859-1',
      delimitersToGuess: [';', '\t', ','],
      complete: (result: ParseResult) => {
        const data: ParseResultData = result.data;
        const header = data.splice(0, 1)[0];

        const xIndex = header.findIndex(
          (h: string) => h.toLowerCase() === 'x' || h.toLowerCase() === 'lng'
        );
        const yIndex = header.findIndex(
          (h: string) => h.toLowerCase() === 'y' || h.toLowerCase() === 'lat'
        );

        this.rows = data
          .filter((row) => row.length > 1)
          .map((row) => {
            const obj = {
              x: '0',
              y: '0',
            };
            for (let i = 0; i < row.length && i < header.length; i++) {
              if (i === xIndex) {
                obj.x = row[i].replace('\n', '');
              } else if (i === yIndex) {
                obj.y = row[i].replace('\n', '');
              } else if (row[i]) {
                obj[header[i].toLowerCase()] = row[i].replace('\n', '');
              } else {
                obj[header[i].toLowerCase()] = '';
              }
            }

            return obj;
          });

        if (
          this.rows &&
          this.rows.length > 0 &&
          ((this.rows[0].x &&
            parseFloat(this.rows[0].x.replaceAll(',', '.')) > 0) ||
            (this.rows[0].y &&
              parseFloat(this.rows[0].y.replaceAll(',', '.')) > 0))
        ) {
          // we have x or y
          // we dont need any more files
          this.needAdditionalFiles = false;
          this.done(this.soilsamplingFiles.txt);
        } else {
          this.needAdditionalFiles = true;
          this.updateFileStatus();
          this.listProperties();
        }
      },
    });
  }

  fetchCoordLayer(): void {
    const dataFetchFunction = this.selectedMappingLayer.newFilestack
      ? this.clientSrv.getGeoDataJson.bind(this.clientSrv)
      : this.clientSrv.getSoilSamplingCoordinateJson.bind(this.clientSrv);

    dataFetchFunction(this.selectedMappingLayer.id).subscribe((featCol) => {
      if (featCol === null) {
        this.dbfFileMissing = true;
        this.shpFileMissing = true;
        this.needAdditionalFiles = true;
        this.snackBar.open(
          this.translateService.t(
            '_markk_err_cant read coordinate layer',
            '_Could not read the chosen layer'
          )
        );
        return;
      }
      if (
        featCol.features.length === 0 ||
        featCol.features.filter((f) => f.geometry.type === 'Point').length === 0
      ) {
        this.dbfFileMissing = true;
        this.shpFileMissing = true;
        this.needAdditionalFiles = true;
        this.snackBar.open(
          this.translateService.t(
            '_markk_err_no coords in coordinate layer',
            '_Could not read any coordinates in the chosen layer'
          )
        );
        return;
      }

      this.soilsamplingFiles.fc = featCol;
      this.dbfFileMissing = false;
      this.shpFileMissing = false;
      this.needAdditionalFiles = false;
      this.noShpRows = false;
      this.listProperties();
    });
  }

  updateFileStatus(): void {
    this.shpFileMissing = this.soilsamplingFiles.shp === null ? true : false;
    this.dbfFileMissing = this.soilsamplingFiles.dbf === null ? true : false;
    this.csvFileMissing = this.soilsamplingFiles.txt === null ? true : false;
    this.needAdditionalFiles =
      !this.dbfFileMissing && !this.shpFileMissing && !this.csvFileMissing
        ? false
        : true;

    if (
      !this.shpFileMissing &&
      !this.dbfFileMissing &&
      this.soilsamplingFiles.fc !== null &&
      this.soilsamplingFiles.fc.features.length === 0
    ) {
      this.noShpRows = true;
    }
  }

  listProperties(): void {
    if (
      this.rows !== null &&
      this.rows.length > 0 &&
      this.soilsamplingFiles.fc !== null &&
      this.soilsamplingFiles.fc.features.length > 0
    ) {
      this.gotFiles = true;

      for (const txtP in this.rows[0]) {
        this.txtProps.push(txtP);
      }

      for (const shpP in this.soilsamplingFiles.fc.features[0].properties) {
        this.shpProps.push(shpP);
      }

      const txtIndex = this.txtProps.indexOf('prøvenummer');
      if (txtIndex > -1) {
        this.selTxtProp = this.txtProps[txtIndex];
      }

      if (this.shpProps.includes('ObjektID')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('ObjektID')];
      } else if (this.shpProps.includes('SampleID')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('SampleID')];
      } else if (this.shpProps.includes('sample_num')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('sample_num')];
      } else if (this.shpProps.includes('SampleId')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('SampleId')];
      } else if (this.shpProps.includes('SAMPLEID')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('SAMPLEID')];
      } else if (this.shpProps.includes('sampleid')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('sampleid')];
      } else if (this.shpProps.includes('OBJEKTID')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('OBJEKTID')];
      } else if (this.shpProps.includes('OBJECTID')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('OBJECTID')];
      } else if (this.shpProps.includes('ObjectID')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('ObjectID')];
      } else if (this.shpProps.includes('objectid')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('objectid')];
      } else if (this.shpProps.includes('objektid')) {
        this.selShpProp = this.shpProps[this.shpProps.indexOf('objektid')];
      }
      this.checkMatch();
    }
  }

  checkMatch(): void {
    this.matchCount = 0;
    this.soilsamplingFiles.fc.features.forEach((f) => {
      let sampleId: number = null;

      // Check if the string contains at least one digit, and nothing else.
      // "^" Start
      // "d" digit
      // "+" one or more
      // $ End
      const numberTest = /^\d+$/;

      const isnum = numberTest.test(f.properties[this.selShpProp]);
      if (isnum) {
        sampleId = Number(f.properties[this.selShpProp]);
      } else {
        sampleId = f.properties[this.selShpProp];
      }

      const row = this.rows.find((r) => {
        const isnum = numberTest.test(f.properties[this.selShpProp]);
        if (isnum) {
          return Number(r[this.selTxtProp]) === sampleId;
        } else {
          return r[this.selTxtProp] === sampleId;
        }
      });

      if (sampleId && row) {
        row['x'] = f.geometry.coordinates[0];
        row['y'] = f.geometry.coordinates[1];
        this.precheckMatch = true;
        this.matchCount++;
      } else {
        this.precheckMatch = false;
      }
    });
  }

  combine(): void {
    if (this.rows !== null && this.soilsamplingFiles.fc !== null) {
      let foundMatch = false;
      this.soilsamplingFiles.fc.features.forEach((f) => {
        const sampleId: number | string = f.properties[this.selShpProp];

        const row = this.rows.find((r) => {
          const compareId = r[this.selTxtProp];

          if (isNaN(Number(sampleId))) {
            return compareId === sampleId;
          } else {
            return Number(compareId) === Number(sampleId);
          }
        });

        if (sampleId && row) {
          row.x = f.geometry.coordinates[0];
          row.y = f.geometry.coordinates[1];
          foundMatch = true;
        }
      });

      if (foundMatch) {
        let txt = '';
        for (const key in this.rows[0]) {
          txt += key + ';';
        }
        txt += '\n';

        this.rows.forEach((r) => {
          for (const key in this.rows[0]) {
            txt += (r[key] || '') + ';';
          }
          txt += '\n';
        });

        // Make sure filending is *.txt
        const f = new File(
          [txt],
          this.soilsamplingFiles.txt.name.substr(
            0,
            this.soilsamplingFiles.txt.name.lastIndexOf('.')
          ) + FILE_EXTENSION.TXT
        );
        this.done(f);
      } else {
        this.snackBar.open(
          this.translateService.t("Couldn't match soil samples and points")
        );
      }
    }
  }

  done(file: File): void {
    this.importModel.files = [file];
    this.importModel.manufacture = 'unknown';
    this.importChange.next(this.importModel);
    if (
      this.deleteCoordinateLayerAfterImport &&
      this.selectedMappingLayer !== null
    ) {
      if (!this.selectedMappingLayer.newFilestack) {
        this.clientSrv
          .softDeleteLayer(this.selectedMappingLayer.id)
          .subscribe();
      }
    }
  }

  reset(): void {
    this.gotFiles = false;
    this.rows = null;
    this.soilsamplingFiles = {
      shp: null,
      txt: null,
      dbf: null,
      shx: null,
      fc: null,
    };
  }
}
