import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  BrukningsenhetModel,
  NewSkifteEnkelModel,
} from 'app/models/api.models';
import * as turf from '@turf/turf';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { forkJoin, fromEvent, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import shp from 'shpjs';
import { FileuploadComponent } from '../fileupload/fileupload.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-field-boundary',
  templateUrl: './field-boundary.component.html',
  styleUrls: ['./field-boundary.component.scss'],
})
export class FieldBoundaryComponent implements OnInit, OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  @Output() fieldsDone = new EventEmitter<NewSkifteEnkelModel[]>();

  snackConfigError: MatSnackBarConfig = {
    panelClass: ['style-error'],
  };
  skiften: NewSkifteEnkelModel[] = [];
  brukenh: BrukningsenhetModel[] = [];
  selectedBrukId = 0;
  rows: [{ [key: string]: any }] = null;
  shpFileMissing = false;
  dbfFileMissing = false;
  needAdditionalFiles = false;
  shpFiles = {
    shp: null,
    dbf: null,
    shx: null,
    fc: null,
  };
  message: Messages[] = [];
  working = false;

  @ViewChild('fileUpload') fileUpload: FileuploadComponent;

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: DvToolbarTranslateService,
    private snackBar: MatSnackBar,
    private clientService: ClientService,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.clientService
      .getBrukningsenheter()
      .pipe(takeUntil(this._unsub$))
      .subscribe((b) => {
        this.brukenh = b;
        this.selectedBrukId = this.brukenh[0].id;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  files(files: File[]): void {
    this.skiften = [];
    this.message = [];
    const reader = new FileReader();
    this.cd.markForCheck();

    files.forEach((file) => {
      switch (file.name.toLowerCase().substr(file.name.lastIndexOf('.'))) {
        case '.zip':
          this.parseZip(file);
          this.fileUpload.clearFiles();
          break;
        case '.dbf':
          this.shpFiles.dbf = file;
          this.parseShp();
          break;
        case '.shp':
          this.shpFiles.shp = file;
          this.parseShp();
          break;
        case '.shx':
          this.shpFiles.shx = file;
          this.parseShp();
          break;
        case '.xml':
        case '.txt':
          reader.onload = (event): void => {
            const doc = this.getXmlDoc(event.target.result);
            if (doc !== null) {
              this.parseXml(doc);
            } else {
              this.message.push({
                message: this.translateService.t(
                  'Selected file is not a valid XML-file'
                ),
                error: true,
              });
              this.cd.markForCheck();
            }

            this.fileUpload.clearFiles();
          };
          reader.readAsText(file, this.siteService.getEncoding());
          break;
        default:
          this.snackBar.open(
            this.translateService.t('Unknown filetype'),
            null,
            { duration: 3000 }
          );
      }
    });
  }

  private parseShp(): void {
    this.updateFileStatus();

    // Don't parse shape if required files are missing
    if (this.shpFileMissing || this.dbfFileMissing) {
      return;
    }

    const shpReader = new FileReader();
    const dbfReader = new FileReader();

    forkJoin([
      fromEvent(shpReader, 'load').pipe(first()),
      fromEvent(dbfReader, 'load').pipe(first()),
    ]).subscribe(() => {
      this.shpFiles.fc = shp.combine([
        shp.parseShp(<any>shpReader.result),
        shp.parseDbf(<any>dbfReader.result, null),
      ]);

      if (
        this.shpFiles.fc.features.length > 0 &&
        this.shpFiles.fc.features[0].geometry.coordinates
      ) {
        const coord =
          this.shpFiles.fc.features[0].geometry.coordinates[0][0][0];

        if (coord > 180 || coord < -180) {
          this.message.push({
            message: this.translateService.t(
              '_err_Coordinates is not WGS84',
              '_coordinates are not WGS 84 standard, and cannot be parsed'
            ),
            error: true,
          });
          this.cd.markForCheck();
        } else {
          this.shpFiles.fc.features.forEach((feature) => {
            this.removeExtraCoordinateValue(feature.geometry.coordinates);
            const s: NewSkifteEnkelModel = <NewSkifteEnkelModel>{};
            s.areal = this.siteService.calculateArea(turf.area(feature));
            s.feature = feature;
            if (s.areal > 0) {
              this.skiften.push(s);
            }
          });
        }
      }
    });

    shpReader.readAsArrayBuffer(this.shpFiles.shp);
    dbfReader.readAsArrayBuffer(this.shpFiles.dbf);
  }

  private removeExtraCoordinateValue(coordinates: number[][][] = []): void {
    coordinates.forEach((coordinate: number[][]) => {
      coordinate.forEach((point) => {
        if (point.length > 2) {
          point.pop();
        }
      });
    });
  }

  parseZip(file: File): void {
    const zipReader = new FileReader();
    zipReader.onload = (): void => {
      shp(zipReader.result)
        .then((geoJson: any) => {
          geoJson.features?.forEach((feature) =>
            this.removeExtraCoordinateValue(feature.geometry?.coordinates)
          );
          this.readGeoJsonInit(geoJson);
        })
        .catch(() => {
          this.message.push({
            message: this.translateService.t(
              "Can't read zipfile. Check for filenames with ~"
            ),
            error: true,
          });
          this.working = false;
          this.cd.markForCheck();
        });
    };
    zipReader.onerror = (e): void => {
      console.log(e);
    };
    zipReader.readAsArrayBuffer(file);
  }

  private readGeoJsonInit(geoJson: any): void {
    if (Array.isArray(geoJson)) {
      if (geoJson[0].fileName.toLowerCase().indexOf('aggps') !== -1) {
        for (const geo of geoJson) {
          if (geo.fileName.toLowerCase().endsWith('boundary')) {
            const arr = geo.fileName.split('/');
            const name = arr[arr.length - 2];
            this.readGeoJsonTrimble(geo, name);
          }
        }
      } else {
        for (const geo of geoJson) {
          this.readGeoJson(geo);
        }
      }
    } else {
      this.readGeoJson(geoJson);
    }
  }

  private readGeoJson(geoJson: any): void {
    if (
      geoJson.features.length > 0 &&
      geoJson.features[0].geometry.coordinates
    ) {
      const coord = geoJson.features[0].geometry.coordinates[0][0];
      if (coord > 180 || coord < -180) {
        this.message.push({
          message: this.translateService.t(
            '_err_Coordinates is not WGS84',
            '_coordinates are not WGS 84 standard, and cannot be parsed'
          ),
          error: true,
        });
        this.cd.markForCheck();
      } else {
        for (const f of geoJson.features) {
          const skifte = <NewSkifteEnkelModel>{
            namn: f.properties['NAME'],
            areal:
              Math.round(this.siteService.calculateArea(turf.area(f)) * 100) /
              100,
            feature:
              f.geometry.type === 'MultiPolygon' ||
              f.geometry.type === 'Polygon'
                ? f
                : null,
          };
          if (skifte.areal > 0) {
            this.skiften.push(skifte);
          }
        }

        if (this.skiften.length === 0) {
          this.message.push({
            message: this.translateService.t(
              '_err_Skifte No valid parcels in file',
              '_No valid parcels in file'
            ),
            error: true,
          });
        }

        this.working = false;
      }
    }
  }

  private readGeoJsonTrimble(geoJson: any, name: string): void {
    if (
      geoJson.features.length > 0 &&
      geoJson.features[0].geometry.coordinates
    ) {
      const coord = geoJson.features[0].geometry.coordinates[0][0];
      if (coord > 180 || coord < -180) {
        this.message.push({
          message: this.translateService.t(
            'Coordinates is not WGS84 and can´t be parsed'
          ),
          error: true,
        });
        this.cd.markForCheck();
      } else {
        for (const f of geoJson.features) {
          const skifte = <NewSkifteEnkelModel>{
            namn: name,
            areal:
              Math.round(this.siteService.calculateArea(turf.area(f)) * 100) /
              100,
            feature: f,
          };
          if (skifte.areal > 0) {
            this.skiften.push(skifte);
          }
        }
        this.working = false;
      }
    }
  }

  private getXmlDoc(fileContent: any): XMLDocument {
    if (typeof fileContent === 'string') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(fileContent, 'application/xml');
      if (doc.documentElement.nodeName === 'parsererror') {
        return null;
      }
      return doc;
    }
  }

  parseXml(xmlDoc: XMLDocument): void {
    if (xmlDoc.documentElement.nodeName === 'SAMI_APPLICATION') {
      this.parseSam(xmlDoc);
    } else if (
      xmlDoc.getElementsByTagName('Country') &&
      xmlDoc.getElementsByTagName('Country')[0] &&
      xmlDoc.getElementsByTagName('Country')[0].textContent === 'NL'
    ) {
      this.parseAgrometius(xmlDoc);
    } else {
      this.message.push({
        message: this.translateService.t('Unknown XML-file'),
        error: true,
      });
      this.cd.markForCheck();
    }

    if (this.skiften.length === 0) {
      this.message.push({
        message: this.translateService.t('File contains no valid fields'),
        error: true,
      });
    }
  }

  private parseSam(xmlDoc: XMLDocument): void {
    const fieldsCollection = xmlDoc.getElementsByTagName('PARCEL');
    const fields = Array.from(fieldsCollection);

    for (const field of fields) {
      const s: any = <any>{};
      const parcelField = field.getElementsByTagName('PARCELNAME');
      if (parcelField && parcelField.length > 0) {
        s.namn = parcelField[0].textContent;
      }

      const valuesCollection = field.getElementsByTagName('VALUE');
      const values = Array.from(valuesCollection);

      for (const value of values) {
        if (value.hasAttribute('Unit')) {
          s.areal = Number(value.textContent);
        }
      }

      const wktField = field.getElementsByTagName('POLYGON');
      if (wktField && wktField.length > 0 && s.areal > 0) {
        s.wkt = wktField[0].textContent;
        s.espg = 3006;
        this.skiften.push(s);
      }
    }
  }

  private parseAgrometius(xmlDoc: XMLDocument): void {
    const fieldsCollection = xmlDoc.getElementsByTagName('CropField');
    const fields = Array.from(fieldsCollection);

    for (const field of fields) {
      const s: NewSkifteEnkelModel = <NewSkifteEnkelModel>{};
      s.namn = field.getElementsByTagName('CropFieldDesignator')[0].textContent;
      s.areal = Number(field.getElementsByTagName('Area')[0].textContent);

      const coordinates = [];

      const posList = field.getElementsByTagName('posList');
      for (let j = 0; j < posList.length; j++) {
        const linearRing = posList[0].textContent;
        const cArray = linearRing.split(' ').map((v) => Number(v));

        coordinates[j] = [];
        cArray.reduce((result, value, index, array) => {
          if (index % 2 === 0) {
            coordinates[j].push(array.slice(index, index + 2));
          }
          return result;
        });

        const l = coordinates[j][0];
        coordinates[j].push(l);
      }

      const poly = turf.polygon(coordinates, {});
      s.feature = poly;
      if (s.areal > 0) {
        this.skiften.push(s);
      }
    }
  }

  updateFileStatus(): void {
    this.shpFileMissing = this.shpFiles.shp === null ? true : false;
    this.dbfFileMissing = this.shpFiles.dbf === null ? true : false;
    this.needAdditionalFiles =
      !this.dbfFileMissing && !this.shpFileMissing ? false : true;
  }

  done(): void {
    this.skiften.forEach((s) => {
      s.brukId = this.selectedBrukId;
    });

    this.fieldsDone.next(this.skiften);
  }
}

class Messages {
  message: string;
  error: boolean;
}
